import React from 'react';
import g from './../../Style.module.css';
import s from './../../../MainMenu/MainMenu.module.css'
import PointDown from './../../Icon/PointDown/PointDown';
class Topic extends React.Component {

    getTopicsList() {
        let topics = this.props.topics;
        let list = [];
        for (var key in topics) {
            //list.push(<div className={g.topic_frame_text}>· {key} - {topics[key]}</div>);
            list.push(<div className={s.topic_frame_text}>· {topics[key]}</div>);
        }

        return list;
    }
    render() {
        return (
            <div className={s.topic_frame} style={{
                display: this.props.hidden ? "none" : "block"
            }}>
                <p><strong><PointDown /> Теми для повторення:</strong></p>
                <div className={s.list}>
                    {this.getTopicsList()}
                </div>
            </div>
        );
    }
}

export default Topic;
