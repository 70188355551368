import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import NotFound from "./screens/NotFound";
import Test from "./screens/Test";
import ReadID from "./screens/Test/ReadID";
import MainMenu from "./screens/MainMenu";

export default () => (
    <Router>
        <Switch>
            <Route exact path="/" component={Test} />
            <Route exact path="/review" component={Test} />
            <Route exact path="/results" component={MainMenu} />
            <Route exact path="/:id" component={ReadID} />
            <Route exact path={"/404"} component={NotFound} />
            <Route path="*" component={NotFound} />
        </Switch>
    </Router>
);
