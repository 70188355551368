import React from "react";
import s from "./MainMenu.module.css";
import g from "../Templates/Style.module.css";
import TopicWithNum from "../Templates/Objects/TopicsWithNum/TopicsWithNum";
import Scores from "./Object/Scores/Scores";
import Axios from 'axios'
class MainMenu extends React.Component {
  state = {
    id: this.props.location.state.id,
    user_answers: this.props.location.state.user_answers,
    user_inputs: this.props.location.state.user_inputs,
    scores: {numCorrect: 0, score12: 0, score200: 0},
    topics: []
  }

  get_scores(user_answers) {
    const ZNO_MAX = 48;
    const DPA_MAX = 44;
    const DPA_open = 8;
    const ZNO_open = 14;
    const DPA_skip = ["29", "30", "33"];
    const double_points = ["27", "28", "29", "30"];
    const ZNO_convert = {
      0: "-",
      1: "-",
      2: "-",
      3: "-",
      4: "-",
      5: "-",
      6: "-",
      7: "-",
      8: "-",
      9: "-",
      10: "-",
      11: 100,
      12: 104,
      13: 107,
      14: 110,
      15: 114,
      16: 117,
      17: 119,
      18: 122,
      19: 125,
      20: 128,
      21: 130,
      22: 133,
      23: 135,
      24: 137,
      25: 140,
      26: 142,
      27: 144,
      28: 146,
      29: 149,
      30: 151,
      31: 153,
      32: 155,
      33: 157,
      34: 159,
      35: 161,
      36: 163,
      37: 165,
      38: 166,
      39: 168,
      40: 170,
      41: 172,
      42: 173,
      43: 175,
      44: 177,
      45: 178,
      46: 180,
      47: 182,
      48: 183,
      49: 184,
      50: 186,
      51: 187,
      52: 189,
      53: 190,
      54: 191,
      55: 192,
      56: 194,
      57: 195,
      58: 196,
      59: 197,
      60: 198,
      61: 199,
      62: 200,
    };
    const DPA_convert = {
      0: 1,
      1: 1,
      2: 1,
      3: 1,
      4: 2,
      5: 2,
      6: 2,
      7: 3,
      8: 3,
      9: 3,
      10: 4,
      11: 4,
      12: 4,
      13: 5,
      14: 5,
      15: 5,
      16: 5,
      17: 5,
      18: 6,
      19: 6,
      20: 6,
      21: 6,
      22: 6,
      23: 6,
      24: 6,
      25: 7,
      26: 7,
      27: 7,
      28: 7,
      29: 7,
      30: 7,
      31: 8,
      32: 8,
      33: 8,
      34: 8,
      35: 8,
      36: 9,
      37: 9,
      38: 9,
      39: 9,
      40: 9,
      41: 10,
      42: 10,
      43: 10,
      44: 10,
      45: 11,
      46: 11,
      47: 11,
      48: 11,
      49: 12,
      50: 12,
      51: 12,
      52: 12,
    };

    let DPA_points = 0;
    let ZNO_points = 0;
    let topics = {
      1: [
        "Тотожність. Тотожні перетворення виразу.",
        "Многочлен. Подібні члени многочлена та їх зведення.",
        "Додавання, віднімання і множення многочленів.",
      ],
      2: [
        "Координатна площина. Приклади графіків залежностей між величинами.",
        "Функція , її графік і властивості.",
      ],
      3: ["Квадратна нерівність. Система двох рівнянь з двома змінними."],
      4: [
        "Суміжні та вертикальні кути, їх властивості.",
        "Властивості кутів, утворених при перетині паралельних прямих січною.",
      ],
      5: ["Ділення з остачею."],
      6: [
        "Многогранник та його елементи. Опуклі многогранники. Призма. Пряма і правильна призми. Паралелепіпед. Піраміда. Правильна піраміда. Перерізи многогранників.",
      ],
      7: ["Найпростіші показникові та логарифмічні рівняння і нерівності."],
      8: [
        "Логарифми та їх властивості. Властивості та графік логарифмічної функції.",
      ],
      9: ["Площа круга. Круговий сектор. Стовпчасті та кругові діаграми."],
      10: [
        "Звичайні дроби. Правильні та неправильні дроби. Звичайні дроби і ділення натуральних чисел. Мішані числа.",
        "Поняття про об’єм тіла. Основні властивості об’ємів. Об’єми призми, паралелепіпеда, піраміди, циліндра, конуса, кулі.",
      ],
      11: [
        "Розкладання квадратного тричлена на лінійні множники.",
        "Основна властивість дробу. Скорочення дробу. Найменший спільний знаменник дробів. Зведення дробів до спільного знаменника. Порівняння дробів.",
      ],
      12: ["Перетворення графіків функцій."],
      13: [
        "Тригонометричні функції числового аргументу. Основні співвідношення між тригонометричними функціями одного аргументу. Формули зведення.",
      ],
      14: [
        "Прямокутник, ромб, квадрат та їх властивості. Трапеція.",
        "Коло, вписане в трикутник.",
      ],
      15: [
        "Раціональні вирази.",
        "Арифметичний квадратний корінь. Властивості арифметичного квадратного кореня.",
      ],
      16: ["Площі бічної та повної поверхонь призми, піраміди."],
      17: ["Система двох лінійних рівнянь з двома змінними."],
      18: ["Визначений інтеграл, його геометричний зміст."],
      19: ["Синус, косинус, тангенс гострого кута прямокутного трикутника."],
      20: ["Синус, косинус, тангенс, кута. Радіанне вимірювання кутів."],
      21: [
        "Функція , її графік і властивості.",
        "Функція , її графік і властивості.",
        "Функція y = x^2, її графік і властивості.",
      ],
      22: [
        "Степінь з натуральним показником. Властивості степеня з натуральним показником.",
        "Логарифми та їх властивості. Властивості та графік логарифмічної функції.",
      ],
      23: [
        "Середня лінія трапеції, її властивості.",
        "Розв’язування прямокутних трикутників.",
        "Теорема Піфагора.",
      ],
      24: [
        "Взаємне розміщення прямих у просторі. Паралельне проектування і його властивості. Зображення фігур у стереометрії. Паралельність прямої та площини. Паралельність площин.",
        "Основні поняття, аксіоми стереометрії та найпростіші наслідки з них.",
      ],
      25: [
        "Відношення. Пропорція. Основна властивість пропорції. Пряма та обернена пропорційна залежність. Поділ числа у даному відношенні.",
        "Відсоткове відношення двох чисел. Відсоткові розрахунки.",
      ],
      26: [
        "Прямокутник, ромб, квадрат та їх властивості. Трапеція.",
        "Теорема Піфагора.",
      ],
      27: [
        "Арифметична та геометрична прогресії, їх властивості. Формули n-го члена арифметичної та геометричної прогресій.",
      ],
      28: [
        "Лінійні рівняння та їх системи як математичні моделі текстових задач.",
      ],
      29: ["Основні правила комбінаторики."],
      30: [
        "Координати середини відрізка. Відстань між двома точками.",
        "Площі прямокутника, паралелограма, ромба, трикутника, трапеції.",
      ],
    };

    let topics_to_review = [];
    let write_topics = [];

    for (var number in user_answers) {
      if (typeof user_answers[number] === "object") {
        let answer_list = user_answers[number];
        let add_points = double_points.includes(number) ? 2 : 1;
        for (var i in answer_list) {
          if (answer_list[i]) {
            if (!DPA_skip.includes(number)) {
              DPA_points += add_points;
            }
            ZNO_points += add_points;
          } else {
            if (!write_topics.includes(number)) {
              write_topics.push(number);
            }
          }
        }
      } else {
        if (user_answers[number]) {
          DPA_points += 1;
          ZNO_points += 1;
        } else {
          write_topics.push(number);
        }
      }
    }

    for (var item of write_topics) {
      for (var topic of topics[item]) {
        topics_to_review.push(topic);
      }
    }

    let DPA_results =
      DPA_points + Math.round((DPA_points / DPA_MAX) * DPA_open);
    let ZNO_results =
      ZNO_points + Math.round((ZNO_points / ZNO_MAX) * ZNO_open);

      this.setState({
        scores: {
                  numCorrect: ZNO_points, 
                  score12: DPA_convert[DPA_results], 
                  score200: ZNO_convert[ZNO_results]
                },
        topics: topics_to_review
      })
    }
  
    open_review = () => {
      if(this.state.id != 'none'){
        let request_body = 'id='+this.state.id +'&e='+'ReviewDiagnosticTestOpened'
        Axios.get('https://hello.mavka.org/addevent?'+request_body);
      }   
      this.props.history.push({
        pathname: '/review',
        state: {user_answers: this.state.user_answers, mode: 'review', user_inputs: this.state.user_inputs, id: this.state.id}
      });
    }

  componentDidMount() {
    this.get_scores(this.state.user_answers);
  }

  render() {
    window.addEventListener("popstate", e => {
      // Nope, go back to your page
      this.props.history.go(1);
    });
    return (
      // <div className={g.background}>
      //   <meta name="viewport" content="height=device-height"></meta>
      //   <div className={[s.page, g.page_].join(" ")}>
      //     <div className={s.header}>
      //       <div className={s.question_title}>
      //         <strong>
      //           Результати діагностичного тесту
      //         </strong>
      //       </div>
      //     </div>
      //     <div className={s.question_body}>
      //         <div className={s.scores_frame}>
      //           <div className={s.title}>
      //             <strong>
      //               з математики
      //             </strong>
      //           </div>
      //             <Scores
      //               numCorrect={this.state.scores.numCorrect}
      //               score12={this.state.scores.score12}
      //               score200={this.state.scores.score200}
      //               click={this.open_review}
      //             />
      //         </div>
      //         <TopicWithNum
      //           topics={this.state.topics}
      //         />

      //     </div>
      //   </div>
      // </div>
      <div className={s.MainMenu}>
        <div className={s.header}>
          <div className={s.main_header}>Результати діагностичного тесту</div>
          <div className={s.sub_header}>з математики</div>
          <div className={s.scores_frame}>
            <Scores
              numCorrect={this.state.scores.numCorrect}
              score12={this.state.scores.score12}
              score200={this.state.scores.score200}
              click={this.open_review}
            />
          </div>
        </div>
        <div className={s.question_body}>
          <TopicWithNum topics={this.state.topics} />
        </div>
      </div>
    );
  }
}

export default MainMenu;
