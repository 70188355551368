import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import LoadingScreen from '../LoadingScreen/LoadingScreen';

class ReadID extends Component {
    initializeID(){
        this.props.history.push({
            pathname: '/',
            state: {id: this.props.match.params.id}
          });
    }

    render() {
        this.initializeID();
        return (
            <LoadingScreen />
        );
    };
}

export default withRouter(ReadID);